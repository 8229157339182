import React, { useEffect, useReducer, useState } from "react";
import { Container, Form, Modal, Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { AiOutlineRight } from "react-icons/ai";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import { TENANT_BUCKET } from '../../common/constants';
import { useMiddletier } from "../../common/middletier";
import { APP_STORE_ACTION, useStoreDispatch, useStoreState } from '../../common/storeContext';
import Loading from '../../components/Loading';
import PageTitle from '../pageTitle/template_01';

import "swiper/css";
import "swiper/css/pagination";
import "./template_01.css";

const COUPON_ACTION = {
  SET_DEFAULT_DATA: 'SET_DEFAULT_DATA',
  SET_SHOW_DATA: 'SET_SHOW_DATA',
  SET_FILTER_TYPES: 'SET_FILTER_TYPES',
  SET_SELECTED_FILTER_TYPE: 'SET_SELECTED_FILTER_TYPE',
  SET_ALL_TYPES: 'SET_ALL_TYPES',
  SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
  SET_ALL_GAME_PROVIDERS: 'SET_ALL_GAME_PROVIDERS',
  SET_SELECTED_GAME_PROVIDER: 'SET_SELECTED_GAME_PROVIDER',
  SET_REFRESH: 'SET_REFRESH',

}

const initialCouponData = {
  defaultData: [],
  showData: [],
  allType: [],
  filterTypes: [{ code: 'ACTIVE', name: 'active' }, { code: 'EXPIRED', name: 'expired' }, { code: 'USED', name: 'used' }, { code: 'USED_EXPIRED', name: 'used_expired' }, { code: 'ALL', name: 'show_all' }],
  selectedFilterType: { code: 'ACTIVE', name: 'active' },
  allGameProviders: [],
  gameProviders: [],
  selectedGameProvider: '',
  refresh: false,
}

const couponReducer = (state, action) => {
  switch (action.type) {

    case COUPON_ACTION.SET_DEFAULT_DATA: {
      const defaultData = action.payload
      return { ...state, defaultData };
    }

    case COUPON_ACTION.SET_SHOW_DATA: {
      const showData = action.payload
      return { ...state, showData };
    }

    case COUPON_ACTION.UPDATE_COUNTDOWN: {
      const updateCountdown = state.showData.map(promotion => ({
        ...promotion, remainingTime: action.payload(promotion)
      }));

      return { ...state, showData: updateCountdown };
    }


    case COUPON_ACTION.SET_ALL_TYPES: {
      const allType = [...action.payload]
      return { ...state, allType };
    }

    case COUPON_ACTION.SET_FILTER_TYPES: {
      const filterTypes = [...action.payload]
      return { ...state, filterTypes };
    }

    case COUPON_ACTION.SET_SELECTED_FILTER_TYPE: {
      const selectedFilterType = state.filterTypes.find((filterTypes) => filterTypes.code === action.payload)
      return { ...state, selectedFilterType };
    }
    case COUPON_ACTION.SET_ALL_GAME_PROVIDERS: {
      const allGameProviders = action.payload
      return { ...state, allGameProviders };
    }

    case COUPON_ACTION.SET_GAME_PROVIDERS: {
      const gameProviders = action.payload
      return { ...state, gameProviders };
    }

    case COUPON_ACTION.SET_SELECTED_GAME_PROVIDER: {
      const selectedGameProvider = action.payload
      return { ...state, selectedGameProvider };
    }
    case COUPON_ACTION.SET_REFRESH: {
      return { ...state, refresh: action.payload ?? false };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const Template_01 = (props) => {
  const { desktopTitleToggle, mobileTitleToggle, dropdownTitle, backgroundImgTitle } = props;

  const appState = useStoreState()
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [couponState, couponDispatch] = useReducer(couponReducer, initialCouponData)
  const [currentItem, setCurrentItem] = useState({})
  const [couponDetailDialogShow, setCouponDetailDialogShow] = useState(false)
  const [claimCouponDialogShow, setClaimCouponDialogShow] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [showTransferGameProviderDialog, setShowTransferGameProviderDialog] = useState(false);

  const { queries, mutation, query } = useMiddletier();


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const { t, i18n } = useTranslation();

  const onChangeType = (newValue) => {
    couponDispatch({ type: COUPON_ACTION.SET_SELECTED_FILTER_TYPE, payload: newValue })
  }

  const onChangeGameProvider = (newValue) => {
    couponDispatch({ type: COUPON_ACTION.SET_SELECTED_GAME_PROVIDER, payload: newValue })
  }

  const onClickCouponDetail = (item) => {
    setCurrentItem(item);
    setCouponDetailDialogShow(true);
  }

  const onClickClaimCoupon = (item) => {
    setCurrentItem(item);
    setClaimCouponDialogShow(true);
  }

  // const formatAmount = (amount, decimalPoint) => {
  //   const factor = Math.pow(10, decimalPoint);
  //   const truncatedAmount = Math.floor(amount * factor) / factor;
  //   const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
  //     minimumFractionDigits: decimalPoint,
  //     maximumFractionDigits: decimalPoint,
  //   });
  //   return formattedAmount;
  // }
  const formatAmount = (amount, decimalPoint) => {
    let strAmount = Number(amount).toString();
    let dotIndex = strAmount.indexOf('.');

    if (dotIndex === -1) {
      strAmount += '.' + '0'.repeat(decimalPoint);
    } else {
      const actualDecimals = strAmount.length - dotIndex - 1;
      if (actualDecimals > decimalPoint) {
        strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
      } else if (actualDecimals < decimalPoint) {
        strAmount += '0'.repeat(decimalPoint - actualDecimals);
      }
    }

    const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });

    return formattedAmount;
  };

  const handleSubmit = ({ forceWithdraw }) => {
    // let method, params
    setLoading(true)

    query({
      method: 'getGamePrivoderBalanceByMember',
      params: [
        { code: 'gameProvider', graphqlType: 'String', required: true, value: couponState.selectedGameProvider },
      ],
      attributes: []
    })
      .then(({ data }) => {

        if (data['getGamePrivoderBalanceByMember'] > 0) {
          setLoading(false)
          setShowTransferGameProviderDialog(true)
        } else {
          claimCouponSubmit()
        }



      }).catch((error) => {
        console.log('error: ', error)
        setLoading(false)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })


  };

  const claimCouponSubmit = () => {
    let method, params
    setLoading(true)
    setShowTransferGameProviderDialog(false)
    console.log('claim coupon')

    method = 'depositGamePrivoderByMember'

    params = [
      { code: 'gameProvider', graphqlType: 'String', required: true, value: couponState.selectedGameProvider },
      { code: 'currency', graphqlType: 'String', required: false, value: appState.user?.currency ?? appState.currency },
      { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(0) },
      { code: 'promotion', graphqlType: 'String', required: false, value: currentItem.id }
    ]

    mutation([{
      method,
      params,
      attributes: []

    }])
      .then(({ data }) => {
        setLoading(false)

        setClaimCouponDialogShow(false)
        // IF STATUS IS APPROVED OR PENDING
        if (data[method].status === 'APPROVED' || data[method].status === 'PENDING') {
          if (data[method].status === 'PENDING') {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: 'Your coupon is being processed. The credit will be transferred to your game wallet shortly.', typeAlert: 'success' }
            });
          } else {

            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: 'Claim Successful', typeAlert: 'success' }
            });
          }

          couponDispatch({ type: COUPON_ACTION.SET_REFRESH, payload: !couponState.refresh })
        }

        else {
          const getErrorMsg = data[method].rejected_reason ? data[method].rejected_reason : (JSON.parse(data[method].response).errMsg)
          console.log('getErrorMsg: ', getErrorMsg)
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: getErrorMsg, typeAlert: 'error' }
          });
        }



      })
      .catch((error) => {
        setLoading(false)
        console.log('error: ', error)


        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }

      })

  };

  useEffect(() => {

    if (appState.loggedIn === false) {
      // setLoading(true)
      if (appState.loginWithToken === true) {
        setLoading(false)
        mutation([
          {
            index: 'loginWithTokenByMember',
            method: 'loginWithTokenByMember',
            params: [],
            attributes: []
          }
        ])
          .then(({ data }) => {
            // console.log('login token')
            setLoading(false)
            // console.log('data: ', data['loginWithTokenByMember'])
            appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data['loginWithTokenByMember'] } })

          })
          .catch((error) => {
            // console.log('login error')
            setLoading(false)
            console.error(error)
            appDispatch({ type: APP_STORE_ACTION.SET_LOGIN_WITH_TOKEN, payload: false })
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })
      } else {
        // console.log('login false')
        setLoading(false)
        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
      }
    } else {
      // console.log('login true')
      setLoading(false)
    }
  }, [mutation, appDispatch, appState, navigate])

  useEffect(() => {
    setLoading(true)

    queries([{
      index: 'getCoupons',
      method: 'getCoupons',
      params: [],
      attributes: []
    }, {
      index: 'getGameProviders',
      method: 'getGameProviders',
      params: [
        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': "ACTIVE", status: 'ACTIVE' }, order: [['index', 'ASC'], ['name', 'ASC']] } },
      ],
      attributes: ['code', 'name', ['_game_provider_game_types', ['type']]]
    },
    {
      index: 'getMemberGameProviders',
      method: 'getMemberGameProviders',
      params: [
        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { member: appState.user?.username } } },
      ],
      attributes: ['id', 'game_provider', "locked", ['_game_provider', ['name']]]
    }]).then(({ data }) => {

      const getCouponActive = data['getCoupons']?.filter(item => item.status === 'ACTIVE')?.length;

      appDispatch({ type: APP_STORE_ACTION.SET_COUPON_ACTIVE_COUNT, payload: getCouponActive })

      const getCouponData = data['getCoupons']?.map((item) => {
        return { ...item, eligible_game_providers: JSON.parse(item.eligible_game_providers) };
      })

      couponDispatch({ type: COUPON_ACTION.SET_DEFAULT_DATA, payload: getCouponData })

      const getFilterData = getCouponData.filter(item => {
        // const category = JSON.parse(item.category);
        return item.status === couponState.selectedFilterType?.code;
      });

      couponDispatch({ type: COUPON_ACTION.SET_SHOW_DATA, payload: getFilterData })

      const checkAllGameProviderLock = data['getGameProviders']?.map(item => {
        const checkGameProvider = data['getMemberGameProviders']?.find(e => e.game_provider === item.code);
        return {
          ...item,
          locked: checkGameProvider ? checkGameProvider.locked : false
        };
      });

      couponDispatch({ type: COUPON_ACTION.SET_ALL_GAME_PROVIDERS, payload: checkAllGameProviderLock })

      // console.log('get Coupon: ', getCouponData)
      // console.log('get getGameProviders: ', checkAllGameProviderLock)
      setLoading(false)


    }).catch((error) => {
      setLoading(false)

      console.log('error: ', error)
    })

  }, [queries, appState?.user?.username, couponState.refresh, appDispatch, couponState.selectedFilterType?.code])

  useEffect(() => {

    if (couponState.selectedFilterType?.code === 'ALL') {
      couponDispatch({ type: COUPON_ACTION.SET_SHOW_DATA, payload: couponState.defaultData })
    }
    else {
      const getFilterData = couponState.defaultData.filter(item => {
        // const category = JSON.parse(item.category);
        return item.status === couponState.selectedFilterType?.code;
      });

      couponDispatch({ type: COUPON_ACTION.SET_SHOW_DATA, payload: getFilterData })
    }


  }, [couponState.selectedFilterType?.code, couponState.defaultData])

  // CHECK FORM SUBMIT
  useEffect(() => {

    if (couponState.selectedGameProvider !== '') {
      setDisabledSubmit(false)
    }
    else {
      setDisabledSubmit(true)
    }

  }, [couponState.selectedGameProvider])

  const widthColumn = isDesktopOrLaptop ? (2 ? 100 / 2 : 0) : (1.15 ? 100 / 1.15 : 0)

  return (
    <>
      <section className={`section`} id={`coupon`}>
        <div className={`coupon_body ${isDesktopOrLaptop ? '' : `coupon_body_m`} layout_body`}>
          <PageTitle desktopToggle={desktopTitleToggle} mobileToggle={mobileTitleToggle} title={'coupon'} id={'coupon_title'} dropdown={dropdownTitle ?? false} backgroundImg={backgroundImgTitle ?? false} />
          <Container>
            {/* SECTION HEADER START*/}
            <Nav variant="pills" className={`coupon_filterTab filterTab tab_pills_color font_tab_pills`} onSelect={(key) => { onChangeType(key) }}>
              {couponState.filterTypes?.filter((item) => {
                if (appState.removeExpired && item.code === 'EXPIRED') {
                  return false;
                }
                if (appState.removeUsedExpired && item.code === 'USED_EXPIRED') {
                  return false;
                }
                return true;
              }).map(function (item, index) {
                return (
                  <Nav.Item key={item.code} >
                    <Nav.Link eventKey={item.code} className={`filterTab_selection ${item.code === couponState.selectedFilterType.code ? 'color_active active' : ''}`}>
                      {item.code === 'ALL' ? t('show_all') : t(item.name)}
                    </Nav.Link>
                  </Nav.Item>
                )
              })}
            </Nav>
            {/* SECTION HEADER END */}
            <div className={`row`} >
              {Object.values(couponState.showData ?? "").map((item, index) => {
                return (
                  <div style={{ width: widthColumn + '%' }} key={`${index}_COUNPON`} className={`card_container`}>
                    {item.status !== 'ACTIVE' && (<img src={`${TENANT_BUCKET}/icon/stamp_coupon_${item.status?.toLowerCase()}_${i18n.resolvedLanguage}.png`} alt={`coupon_${item.status?.toLowerCase()}`} className="stamp_coupon" />)}
                    <div className={`card_coupon_${item.status?.toLowerCase()} card_coupon`}>
                      <img src={`${TENANT_BUCKET}/icon/coupon_${item.status?.toLowerCase()}.png`} alt={`coupon_${item.status?.toLowerCase()}`} />
                      <div className="coupon_content_wrapper">
                        <div className="coupon_header_container">
                          <div>{appState?.currency}</div>
                          <div>{formatAmount(item?.target_bonus ?? 0, appState.currencyFormat.decimal_point)}</div>
                          <div>{t('cashback')}</div>
                        </div>
                        <div className="coupon_body_container">
                          <div className="coupon_title">{item?.name}</div>
                          <div className="coupon_expireTime">{t('expire_at')} : {item?.last_effective_date}</div>
                          <div className="coupon_claimInfo_container">
                            <button className="color_button button_couponClaim" onClick={() => onClickClaimCoupon(item)} type="button" >{t('claim')}</button>
                            <div className="coupon_tnc" onClick={() => onClickCouponDetail(item)}>{t('coupon_tnc')}  <AiOutlineRight /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Container>
        </div>
      </section>
      {
        couponDetailDialogShow && (<Modal
          show={couponDetailDialogShow}
          onHide={() => setCouponDetailDialogShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="couponDetailModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
              {t('coupon_details')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="promoClaim_container">
              {
                currentItem && (
                  <div className="promoClaim_content">
                    <div className="promoClaim_content_row">
                      <label> {t('coupon')}</label>
                      <p>{currentItem.name}</p>
                    </div>
                    <div className="promoClaim_content_row">
                      <label> {t('expire_at')}</label>
                      <p>{currentItem.last_effective_date}</p>
                    </div>
                    <div className="promoClaim_content_row">
                      <label> {t('validity_day')}</label>
                      <p>{t(`${currentItem?.validity_day}`)}</p>
                    </div>
                    <div className="promoClaim_content_row">
                      <label> {t('target_type')}</label>
                      <p>{t(`${currentItem.target_type.toLowerCase().replace("_", "")}`)}</p>
                    </div>
                    <div className="promoClaim_content_row">
                      <label> {t('target_multiplier')}</label>
                      <p>{t(`${currentItem?.target_multiplier}`)}</p>
                    </div>
                    <div className="promoClaim_content_row">
                      <label> {t('provider')}</label>
                      {currentItem?.eligible_game_providers.map(function (item) {
                        const _item = couponState.allGameProviders.find(e => e.code === item);
                        return (
                          <p>{_item?.name}</p>
                        )
                      })}
                    </div>

                  </div>
                )
              }
            </div>
          </Modal.Body>
        </Modal>)
      }

      {
        claimCouponDialogShow && (<Modal
          show={claimCouponDialogShow}
          onHide={() => setClaimCouponDialogShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="claimCouponModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
              {t('Claim Coupon')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>{t('game_provider')}
              <span className="form_required_input">* </span>
            </Form.Label>
            <Form.Select aria-label="transferTo" className="input_transferTo" value={couponState.selectedTransferToGameProvider} onChange={(evt) => { onChangeGameProvider(evt.target.value) }}>
              <option hidden></option>
              {currentItem?.eligible_game_providers.map(function (item) {
                const _item = couponState.allGameProviders.find(e => e.code === item);
                // console.log('coupongp',_item)
                return _item ? (
                  <option value={item} key={item}>{_item?.name}</option>
                ) : null
              })}
            </Form.Select>
            <button className="font_button color_button button_claimCoupon" type='button' value={t('submit')} disabled={disabledSubmit} onClick={() => handleSubmit({ forceWithdraw: false })} >Claim</button>
          </Modal.Body>
        </Modal>)
      }
      {isLoading && (<Loading />)}

      <Modal show={showTransferGameProviderDialog} onHide={() => setShowTransferGameProviderDialog(false)} centered id="transferGameProviderDialog">
        <Modal.Header>
          <Modal.Title>{t('transferGameProvider_title')}</Modal.Title>
          {/* <Modal.Title>{t('reminder')}</Modal.Title> */}

        </Modal.Header>
        <Modal.Body>
          <p>{t('transferGameProvider_content')}</p>

        </Modal.Body>
        <Modal.Footer>
          <button className="cancel_button" onClick={() => setShowTransferGameProviderDialog(false)}>
            {t('cancel')}
          </button>
          <button className="confirm_button" onClick={() => claimCouponSubmit()}>
            {t('confirm')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}





export default Template_01;
